export const onBlurScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const splitCommaSeparatedValues = (keys: string): string[] => {
  if (!keys) {
    return [];
  }

  return keys
    .split(',')
    .map((key) => key.trim().toLowerCase())
    .filter(Boolean); // This will remove any empty strings resulting from extra commas or spaces
};

export const getTimeStampValueInSeconds = (timestamp?: number) => {
  if (!timestamp) {
    return timestamp;
  }
  const MILISECONDS_DEVISOR = 1000;
  const MICROSECONDS_DEVISOR = 1000000;
  const NANOSECONDS_DEVISOR = 1000000000;
  const timestampLength = timestamp.toString().length;
  let divisor = 1;

  switch (timestampLength) {
    case 13:
      divisor = MILISECONDS_DEVISOR; // Milliseconds to seconds
      break;
    case 16:
      divisor = MICROSECONDS_DEVISOR; // Microseconds to seconds
      break;
    case 19:
      divisor = NANOSECONDS_DEVISOR; // Nanoseconds to seconds
      break;
    default:
      divisor = MILISECONDS_DEVISOR; // Milliseconds to seconds
      break;
  }

  return Math.floor(timestamp / divisor);
};
